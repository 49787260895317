import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SiteHeader from '../components/SiteHeader'
import LogoBanner from '../components/LogoBanner'
import NextSteps from '../components/NextSteps'
import device from '../device'
import Button from '../components/Button'

const HomeHeader = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(143deg, var(--blue-600) 20%, var(--blue-500) 100%);
    padding-bottom: 120px;
    height: 100%;
    pointer-events: none;
    opacity: 0.7;

    @supports (mix-blend-mode: multiply) {
      opacity: 1;
      mix-blend-mode: multiply;
    }
  }

  padding-bottom: 120px;

  ${SiteHeader} {
    position: relative;
    z-index: 101;
    pointer-events: auto;
  }

  h1 {
    color: var(--white);
    font-size: 30px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    position: relative;

    border-bottom: 4px solid var(--red);
    display: inline-block;

    @media ${device.mobileL} {
      font-size: 40px;
    }

    @media ${device.tablet} {
      font-size: 70px;
      max-width: 500px;
      padding-bottom: 60px;
      margin-bottom: 60px;
    }
  }

  p {
    color: var(--white);
    font-size: 20px;
    margin-top: 0;
    line-height: 1.5;
    position: relative;

    @media ${device.mobileL} {
      font-size: 24px;
    }

    @media ${device.tablet} {
      font-size: 30px;
      max-width: 650px;
    }
  }
`

const SolutionLinks = styled.div`
  background: var(--blue-200);
  background-image: linear-gradient(0deg, var(--blue-200) 20%, var(--blue-300) 100%);
  padding: 10px;
  z-index: 100;
  position: relative;

  ul {
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-bottom: 40px;
    margin-top: -100px;

    @media ${device.tablet} {
      display: flex;
    }

    li {
      background: var(--white);
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 2px 10px 0px var(--blue-shadow-dark);
      margin: 20px 0 40px;

      @media ${device.tablet} {
        margin: 0 5px;
        max-width: 320px;

        :first-child {
          margin-left: 0;
        }
  
        :last-child {
          margin-right: 0;
        }
      }

      img {
        height: 200px;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }

      .content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1;

        h3 {
          color: var(--blue-600);
          font-size: 20px;
          margin-bottom: 30px;
        }

        p {
          color: var(--gray-500);
          font-size: 16px;
          margin-bottom: 60px;
        }

        ${Button} {
          font-size: 14px;
          font-weight: 500;
          background: 0;
          border: 1px solid var(--blue-600);
          color: var(--blue-600);
          display: block;
          justify-self: flex-end;
          transition: background 100ms linear;

          &:hover {
            background: var(--blue-200);
            transition: background 250ms linear;
          }
        }
      }
    }
  }
`

const HomeContent = styled.div`
  background: var(--blue-100);
  padding: 80px 20px;
  text-align: center;

  p {
    color: var(--blue-600);

    @media ${device.mobileL} {
      font-size: 24px;
    }

    @media ${device.tablet} {
      font-size: 30px;
      line-height: 1.4;
    }
  }

  ${Button} {
    margin-top: 20px;
  }
`


const IndexPage = ({ data: { prismicHomePage } }) => {
  const { data } = prismicHomePage
  return (
    <Layout>
      <HomeHeader style={{ backgroundImage: `url(${data.background_image.url})`}}>
        <SiteHeader darkMode />
        <Container>
          <h1>{data.title.text}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.introduction.html  }} />
        </Container>
      </HomeHeader>

      <SolutionLinks>
        <Container>
          <ul>
            {data.solution_links.map(({ heading, description, image, button_label, link }) => (
              <li key={heading.text}>
                <img src={image.url} alt={heading.text} />
                <div className="content">
                  <div>
                    <h3>{heading.text}</h3>
                    <p>{description.text}</p>
                  </div>
                  <Button
                    label={button_label.text}
                    linkTo={link.url}
                  />
                </div>
              </li>
            ))}
          </ul>
        </Container>
      </SolutionLinks>

      <HomeContent>
        <Container>
        <div dangerouslySetInnerHTML={{ __html: data.content.html  }} />
        <Button 
          linkTo="/about" 
          label="Find out more about Charthouse"
        />
        </Container>
      </HomeContent>

      <Container>
        <LogoBanner />
      </Container>
      
      {data.next_steps && data.next_steps.length > 0 && 
        <NextSteps
          steps={data.next_steps}
        />
      }

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HopePageQuery {
    prismicHomePage {
      data {
        title {
          text
        }
        introduction {
          html
        }
        background_image {
          url
        }
        solution_links {
          heading {
            text
          }
          description {
            text
          }
          image {
            url
          }
          button_label {
            text
          }
          link {
            url
          }
        }
        content {
          html
        }
        next_steps {
          heading {
            text
          }
          message {
            text
          }
          button_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`